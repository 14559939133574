import React from 'react';

const LogoIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.66272 11.4187L6.33042 10.5029C6.6331 10.0877 7.13086 9.89254 7.61347 9.99986C7.98713 10.0829 8.22692 10.4735 8.14905 10.8722C8.14261 10.9052 8.13405 10.9377 8.12347 10.9694C7.56878 12.6292 7.3922 13.7254 7.59373 14.2582C8.42336 13.2064 12.6617 6.34789 14.0013 6.02233C14.4485 5.91363 14.8937 6.21234 14.9955 6.68954C15.03 6.85099 15.0214 7.01951 14.9707 7.176C13.7049 11.081 13.169 13.4422 13.363 14.2597C14.0315 13.256 15.0244 11.9052 16.3418 10.2072C16.8366 9.56939 17.6261 9.28356 18.3825 9.46834C19.0477 9.63083 19.4634 10.3379 19.3113 11.0476C19.3103 11.0523 19.3093 11.0569 19.3081 11.0616C18.8497 13.0959 18.6798 14.286 18.7984 14.6322C18.8507 14.5897 18.9039 14.5461 18.9578 14.5014C19.562 14.001 20.3186 13.7571 21.0813 13.8169L27 14.2803L23.0787 14.7561C21.984 14.8889 20.9813 15.4733 20.2851 16.3845L19.3467 17.6125C19.0395 18.0145 18.5675 18.2309 18.0827 18.192C17.601 18.1533 17.2028 17.7763 17.1095 17.2707C16.8804 16.0288 16.8437 14.5629 16.9995 12.8729C15.822 14.7501 14.8004 17.4498 13.9346 20.9721C13.7858 21.5777 13.2728 22 12.686 22C12.1256 22 11.6666 21.525 11.6494 20.9274L11.3899 11.921L8.88042 17.1977C8.61925 17.7469 8.04457 18.0399 7.48004 17.9118C6.88196 17.7761 6.38719 17.3301 6.16187 16.7236L5.88067 15.9667C5.48277 14.8957 4.58473 14.1282 3.51829 13.9478C2.34553 13.7494 1.17276 13.551 0 13.3526L2.61228 13.1555C3.81403 13.0648 4.92303 12.4334 5.66272 11.4187Z"
        fill="white"
      />
    </svg>
  );
};

export default LogoIcon;
