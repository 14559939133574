import { RadarEntry } from '@backstage-community/plugin-tech-radar';

const entries = new Array<RadarEntry & { quadrant: 'datastores' }>();

// Redis (Memory Store)
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2024-10-10'),
      description:
        'Memory Store Redis has been adopted to handle caching and session management, optimizing performance for high-traffic services.',
    },
  ],
  key: 'redis-memory-store',
  id: 'redis-memory-store',
  title: 'Redis (Memory Store)',
  quadrant: 'datastores',
  links: [
    {
      url: 'https://cloud.google.com/memorystore/docs/redis',
      title: 'Learn more',
    },
  ],
  description:
    'Memory Store Redis is a managed in-memory key-value store used for high-performance caching and real-time analytics, reducing latency in user interactions.',
});

// Firestore
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2024-10-10'),
      description:
        'Firestore has been adopted as the go-to NoSQL database for scaling the application while maintaining flexibility and real-time sync across devices.',
    },
  ],
  key: 'firestore',
  id: 'firestore',
  title: 'Firestore',
  quadrant: 'datastores',
  links: [
    {
      url: 'https://firebase.google.com/products/firestore',
      title: 'Learn more',
    },
  ],
  description:
    'Cloud Firestore is a flexible, scalable NoSQL cloud database to store and sync data between users in real time, highly integrated with Firebase for mobile and web apps.',
});

export const datastoreEntries = entries;
