import { RadarEntry } from '@backstage-community/plugin-tech-radar';

const entries = new Array<RadarEntry & { quadrant: 'infrastructure' }>();

// Cloud Run
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2024-10-10'),
      description:
        'Cloud Run is adopted for deploying serverless containers, offering flexibility and scalability for stateless services.',
    },
  ],
  key: 'cloud-run',
  id: 'cloud-run',
  title: 'Cloud Run',
  quadrant: 'infrastructure',
  links: [
    {
      url: 'https://cloud.google.com/run',
      title: 'Learn more',
    },
  ],
  description:
    'Cloud Run is a fully managed compute platform that automatically scales stateless containers based on HTTP requests. It supports any programming language and integrates seamlessly with Firestore and other Google Cloud services.',
});

// Cloud Deploy
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2024-10-10'),
      description:
        'Cloud Deploy has been adopted as the continuous delivery service for automating and managing releases to Google Kubernetes Engine and Cloud Run.',
    },
  ],
  key: 'cloud-deploy',
  id: 'cloud-deploy',
  title: 'Cloud Deploy',
  quadrant: 'infrastructure',
  links: [
    {
      url: 'https://cloud.google.com/deploy',
      title: 'Learn more',
    },
  ],
  description:
    'Cloud Deploy automates delivery pipelines, helping teams release code faster and more reliably by managing CI/CD workflows integrated with GKE and Cloud Run.',
});

// Log Explorer
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2024-10-10'),
      description:
        'Log Explorer is adopted as the primary tool for investigating, analyzing, and troubleshooting application logs across Google Cloud services.',
    },
  ],
  key: 'log-explorer',
  id: 'log-explorer',
  title: 'Log Explorer',
  quadrant: 'infrastructure',
  links: [
    {
      url: 'https://cloud.google.com/logging/docs/view/logs-explorer-interface',
      title: 'Learn more',
    },
  ],
  description:
    'Log Explorer provides advanced querying and log analysis capabilities, making it easier to monitor and troubleshoot cloud applications in real-time.',
});

// Backstage
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2024-10-10'),
      description:
        'Backstage is adopted to provide a unified developer portal for managing services, CI/CD pipelines, and documentation.',
    },
  ],
  key: 'backstage',
  id: 'backstage',
  title: 'Backstage',
  quadrant: 'infrastructure',
  links: [
    {
      url: 'https://backstage.io/',
      title: 'Learn more',
    },
  ],
  description:
    'Backstage is an open-source platform for building developer portals, enabling teams to manage all infrastructure services and documentation from a single interface.',
});

export const infrastructureEntries = entries;
