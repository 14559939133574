import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
} from '@backstage/core-plugin-api';
import {
  ScmAuth,
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import { GithubCodeSearchClient } from '@internal/backstage-plugin-github-code-search';
import { TechRadarClient } from './lib/techradar/client';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory(techRadarApiRef, new TechRadarClient()),
  ScmAuth.createDefaultApiFactory(),
  GithubCodeSearchClient.createDefaultApiFactory(),
];
