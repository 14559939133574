import { RadarEntry } from '@backstage-community/plugin-tech-radar';

const entries = new Array<RadarEntry & { quadrant: 'languages' }>();

// TypeScript
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2024-10-10'),
      description:
        'TypeScript is adopted as the primary language for all new codebases, providing static typing and enhancing maintainability, scalability, and developer experience.',
    },
  ],
  key: 'typescript',
  id: 'typescript',
  title: 'TypeScript',
  quadrant: 'languages',
  links: [
    {
      url: 'https://www.typescriptlang.org/',
      title: 'Learn more',
    },
  ],
  description:
    'TypeScript is a superset of JavaScript that adds static typing, enabling better tooling and more predictable code, particularly useful in large-scale projects.',
});
export const languagesEntries = entries;
