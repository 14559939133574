import React from 'react';
import { AppTheme } from '@backstage/core-plugin-api';
import {
  BackstageTypography,
  createBaseThemeOptions,
  createUnifiedTheme,
  palettes,
  UnifiedThemeProvider,
} from '@backstage/theme';
import ABCDiatypeRegular from '../assets/fonts/ABCDiatype-Regular.woff2';
import ABCDiatypeBold from '../assets/fonts/ABCDiatype-Bold.woff2';
import ABCDiatypeMedium from '../assets/fonts/ABCDiatype-Medium.woff2';

// Accent colors #F97316 - orange, #F43F5E - pink

const ABCDiatypeRegularFont = {
  fontFamily: 'ABC Diatype',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
      local('ABC Diatype'),
      url(${ABCDiatypeRegular}) format('woff2')
    `,
};

const ABCDiatypeBoldFont = {
  fontFamily: 'ABC Diatype',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
      local('ABC Diatype Bold'),
      url(${ABCDiatypeBold}) format('woff2')
    `,
};

const ABCDiatypeMediumFont = {
  fontFamily: 'ABC Diatype',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
      local('ABC Diatype Medium'),
      url(${ABCDiatypeMedium}) format('woff2')
    `,
};

// Typography with ABC Diatype
const typography: BackstageTypography = {
  htmlFontSize: 16,
  fontFamily: '"ABC Diatype", sans-serif',
  h1: {
    fontFamily: '"ABC Diatype", sans-serif',
    fontSize: '2.8rem', // Slightly larger
    fontWeight: 700,
    marginBottom: 24, // Increase margin for better spacing
  },
  h2: {
    fontFamily: '"ABC Diatype", sans-serif',
    fontSize: '2.2rem', // Increase size for h2
    fontWeight: 600,
    marginBottom: 20,
  },
  h3: {
    fontFamily: '"ABC Diatype", sans-serif',
    fontSize: '1.9rem',
    fontWeight: 500,
    marginBottom: 18,
  },
  h4: {
    fontFamily: '"ABC Diatype", sans-serif',
    fontSize: '1.6rem',
    fontWeight: 500,
    marginBottom: 14,
  },
  h5: {
    fontFamily: '"ABC Diatype", sans-serif',
    fontSize: '1.4rem',
    fontWeight: 400,
    marginBottom: 12,
  },
  h6: {
    fontFamily: '"ABC Diatype", sans-serif',
    fontSize: '1.2rem',
    fontWeight: 400,
    marginBottom: 10,
  },
};

export const speechifyTheme: AppTheme = {
  id: 'speechify-dark',
  title: 'Speechify Dark',
  variant: 'dark',
  Provider: ({ children }) => (
    <UnifiedThemeProvider
      theme={createUnifiedTheme({
        fontFamily: 'ABC Diatype',
        components: {
          MuiPaper: {
            styleOverrides: {
              root: {
                boxShadow: 'none !important', // Remove box shadow
                border: '1px solid #373737', // Add border
              },
            },
          },
          // Override MuiCssBaseline
          MuiCssBaseline: {
            styleOverrides: {
              '@font-face': [
                ABCDiatypeRegularFont,
                ABCDiatypeBoldFont,
                ABCDiatypeMediumFont,
              ],
              a: {
                textDecoration: 'none', // Remove underline
              },
            },
          },
          MuiButtonBase: {
            defaultProps: {
              disableRipple: true,
            },
          },
          MuiTableRow: {
            styleOverrides: {
              root: {
                backgroundColor: '#1e1e1e', // Darker background
                borderBottom: '1px solid #373737',
                '&:last-child': {
                  borderBottom: 0,
                },
              },
            },
          },
          BackstageSelectInputBase: {
            styleOverrides: {
              input: {
                border: '1px solid #373737 !important', // Add border
              },
            },
          },
          // Override BackstageHeader
          BackstageHeader: {
            styleOverrides: {
              header: {
                backgroundImage: 'none', // Darker background
                boxShadow: 'none',
                borderBottom: '1px solid #373737',
              },
            },
          },
          BackstageTableHeader: {
            styleOverrides: {
              header: {
                borderTop: '1px solid #373737',
                borderBottom: '1px solid #373737',
              },
            },
          },
          // Override BackstageSidebar
          BackstageSidebar: {
            styleOverrides: {
              drawer: {
                borderRight: '1px solid #373737',
                backgroundColor: '#181818', // Darker background
                color: '#a3a3a3',
              },
            },
          },
          // Override BackstageSidebarItem
          BackstageSidebarItem: {
            styleOverrides: {
              selected: {
                borderLeft: '2px solid #F97316 !important', // Orange border
              },
              iconContainer: {
                accentColor: '#F97316',
              },
            },
          },
        },
        ...createBaseThemeOptions({
          palette: {
            ...palettes.dark, // Dark palette
            primary: {
              main: '#fff', // Purple as primary color
            },
            secondary: {
              main: '#fff', // White for secondary elements
            },
            background: {
              default: '#181818', // Dark background
              paper: '#1e1e1e', // Darker paper background
            },
            text: {
              primary: '#ffffff', // White text for readability
              secondary: '#d1d1d1', // Gray for secondary text
            },
          },
          typography, // Apply the custom typography
        }),
      })}
    >
      {children}
    </UnifiedThemeProvider>
  ),
};

export default speechifyTheme;
