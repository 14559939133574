import { Page, Content, InfoCard } from '@backstage/core-components';
import {
  HomePageCompanyLogo,
  HomePageStarredEntities,
  TemplateBackstageLogo,
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { EntityTeamPullRequestsCard } from '@backstage-community/plugin-github-pull-requests-board';
import { EntityProvider } from '@backstage/plugin-catalog-react';
import { GroupEntity } from '@backstage/catalog-model';

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: { borderStyle: 'none' },
}));
const useLogoStyles = makeStyles(theme => ({
  container: { margin: theme.spacing(5, 0) },
  svg: { width: 'auto', height: 100 },
  path: { fill: '#7df3e1' },
}));

const mockGroupEntity: GroupEntity = {
  apiVersion: 'backstage.io/v1alpha1',
  kind: 'Group',
  metadata: {
    name: 'platform',
    namespace: 'default',
    annotations: {
      'backstage.io/managed-by-location':
        'url:https://github.com/orgs/SpeechifyInc/teams/platform',
      'backstage.io/managed-by-origin-location':
        'url:https://github.com/orgs/SpeechifyInc/teams/platform',
      'backstage.io/view-url':
        'https://github.com/orgs/SpeechifyInc/teams/platform',
      'backstage.io/edit-url':
        'https://github.com/orgs/SpeechifyInc/teams/platform/edit',
      'backstage.io/source-location':
        'url:https://github.com/orgs/SpeechifyInc/teams/',
      'github.com/team-slug': 'SpeechifyInc/platform',
    },
  },
  spec: {
    type: 'team',
    profile: {
      displayName: 'Platform',
      picture: 'https://avatars.githubusercontent.com/t/6370544?s=400&v=4',
    },
    children: [],
  },
};

export const HomePage = () => {
  const classes = useStyles();
  const { svg, path, container } = useLogoStyles();
  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <HomePageCompanyLogo
              className={container}
              logo={<TemplateBackstageLogo classes={{ svg, path }} />}
            />
            <Grid container item xs={12} justifyContent="center">
              <HomePageSearchBar
                InputProps={{
                  classes: {
                    root: classes.searchBarInput,
                    notchedOutline: classes.searchBarOutline,
                  },
                }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <EntityProvider entity={mockGroupEntity}>
                  <EntityTeamPullRequestsCard pullRequestLimit={20} />
                </EntityProvider>
              </Grid>
              {/** <Grid item xs={12} md={3}>
                <HomePageStarredEntities title="Your favorites" />
              </Grid>
               */}
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
