import { RadarEntry } from '@backstage-community/plugin-tech-radar';

const entries = new Array<RadarEntry & { quadrant: 'libraries' }>();

// NestJS
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2024-10-10'),
      description:
        'NestJS is adopted as the primary backend framework for building efficient, scalable, and maintainable server-side applications.',
    },
  ],
  key: 'nestjs',
  id: 'nestjs',
  title: 'NestJS',
  quadrant: 'libraries',
  links: [
    {
      url: 'https://nestjs.com/',
      title: 'Learn more',
    },
  ],
  description:
    'NestJS is a progressive Node.js framework, building on Express.js with TypeScript, providing dependency injection, an opinionated structure, and seamless integration with other tools.',
});

// es-toolkit
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2024-10-10'),
      description:
        'es-toolkit is currently being trialed to enhance the development of the API functions, with great potential for improved type safety and developer experience.',
    },
  ],
  key: 'es-toolkit',
  id: 'es-toolkit',
  title: 'ES Toolkit',
  quadrant: 'libraries',
  links: [
    {
      url: 'https://www.npmjs.com/package/es-toolkit',
      title: 'Learn more',
    },
  ],
  description:
    'A modern toolkit aimed at making ES6+ development easier with advanced features for cleaner syntax, better error handling, and TypeScript integration.',
});

// Pino
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2024-10-10'),
      description:
        'Pino is adopted as the lightweight, high-performance logger for structured logs, offering low overhead and good integration with modern observability tools.',
    },
  ],
  key: 'pino',
  id: 'pino',
  title: 'Pino',
  quadrant: 'libraries',
  links: [
    {
      url: 'https://getpino.io/',
      title: 'Learn more',
    },
  ],
  description:
    'Pino is a fast, low-overhead JSON logger for Node.js, ideal for high-performance logging, designed to integrate well with distributed systems and observability platforms.',
});

// React
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2024-10-10'),
      description:
        'React is adopted as the core frontend framework for building dynamic, component-driven user interfaces across all platforms.',
    },
  ],
  key: 'react',
  id: 'react',
  title: 'React',
  quadrant: 'libraries',
  links: [
    {
      url: 'https://reactjs.org/',
      title: 'Learn more',
    },
  ],
  description:
    'React is a declarative, component-based library for building user interfaces, particularly well-suited for single-page applications with dynamic content.',
});

// Next.js
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2024-10-10'),
      description:
        'Next.js is adopted for server-side rendering and static site generation, providing excellent performance and SEO improvements for web applications.',
    },
  ],
  key: 'nextjs',
  id: 'nextjs',
  title: 'Next.js',
  quadrant: 'libraries',
  links: [
    {
      url: 'https://nextjs.org/',
      title: 'Learn more',
    },
  ],
  description:
    'Next.js is a React framework for production, optimized for server-side rendering, static site generation, and improved developer experience with API routes and file-based routing.',
});

export const librariesEntries = entries;
