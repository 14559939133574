import {
  RadarRing,
  RadarQuadrant,
  RadarEntry,
  TechRadarApi,
} from '@backstage-community/plugin-tech-radar';
import { datastoreEntries } from './quadrants/datastores';
import { infrastructureEntries } from './quadrants/infrastructure';
import { languagesEntries } from './quadrants/languages';
import { librariesEntries } from './quadrants/libraries';

const rings = new Array<RadarRing>();
rings.push({
  id: 'adopt',
  name: 'ADOPT',
  color: '#5BA300',
  description:
    'Technologies that are mature, well-established, and recommended for immediate use. They have been proven to deliver value and are considered safe to implement across projects.',
});
rings.push({
  id: 'trial',
  name: 'TRIAL',
  color: '#009EB0',
  description:
    'Technologies worth experimenting with on a trial basis. They show promise, but need more real-world testing before being fully adopted in production environments.',
});
rings.push({
  id: 'assess',
  name: 'ASSESS',
  color: '#C7BA00',
  description:
    'Emerging technologies under evaluation. These tools and frameworks are being closely monitored to assess their viability and potential benefit in future projects.',
});
rings.push({
  id: 'hold',
  name: 'HOLD',
  color: '#E09B96',
  description:
    'Technologies that are not recommended for adoption at this time. They may have significant limitations or risks, and should be avoided or phased out in favor of more reliable alternatives.',
});

const quadrants = new Array<RadarQuadrant>();
quadrants.push({ id: 'infrastructure', name: 'Infrastructure' });
quadrants.push({ id: 'libraries', name: 'Libraries' });
quadrants.push({ id: 'languages', name: 'Languages' });
quadrants.push({ id: 'datastores', name: 'Datastores' });

const entries = new Array<RadarEntry>();

entries.push(
  ...datastoreEntries,
  ...infrastructureEntries,
  ...languagesEntries,
  ...librariesEntries,
);

export class TechRadarClient implements TechRadarApi {
  async load() {
    return {
      entries,
      quadrants,
      rings,
    };
  }
}
