import {
  AnyApiFactory,
  createApiFactory,
  createApiRef,
  DiscoveryApi,
  discoveryApiRef,
  FetchApi,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { CodeSearchResult } from '../types';

export const githubCodeSearchApiRef = createApiRef<GithubCodeSearchApi>({
  id: 'plugin.github-code-search.service',
});

export interface GithubCodeSearchApi {
  searchCode(query: string): Promise<CodeSearchResult[]>;
}

export class GithubCodeSearchClient implements GithubCodeSearchApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  async searchCode(query: string): Promise<CodeSearchResult[]> {
    if (!query || query.trim().length === 0) {
      throw new Error('Search query cannot be empty');
    }

    const baseUrl = await this.discoveryApi.getBaseUrl('github-code-search');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/search?query=${encodeURIComponent(query)}`,
    );

    if (!response.ok) {
      throw new Error(`Failed to search code: ${response.statusText}`);
    }

    const data = await response.json();
    return data as CodeSearchResult[];
  }

  static createDefaultApiFactory(): AnyApiFactory {
    return createApiFactory({
      api: githubCodeSearchApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new GithubCodeSearchClient({
          discoveryApi,
          fetchApi,
        }),
    });
  }
}
